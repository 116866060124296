.wrapper {
  display: flex;
  flex-direction: column;
  max-width: 800px;
}
.xButton {
  position: absolute;
  top: 4px;
  right: 4px;
}

.background {
  display: flex;
  position: relative;
  flex-direction: column;
  background: #f5f2f0;
  padding: 38px;
}

.header {
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;
  font-family: DMSerifDisplay;
}

.button {
  margin-top: 8px;
  width: 100%;
}
